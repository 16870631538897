import Content from "../components/Content/Content";
// import { Link } from "react-router-dom";
import Button from "../components/Button/Button";
// import CallToAction from "../components/CallToAction/CTA";
import Hero from "../components/Hero/Hero";
// import Section from "../components/Section/Section";
import Wrapper from "../components/Wrapper/Wrapper";
import Quote from "../components/Quote/Quote";
import { HeroGridP } from "../components/Hero/Hero.style";
import { HashLink } from "react-router-hash-link";
// import HLCallToAction from "../components/HashLinkCTA/HLCTA";

const HomePage = () => {
  return (
    <div>
      {/* <div style={{ backgroundColor: "red", color: "white", padding: "1rem", textAlign: "center" }}>
        <p>
          The web hosting provider for STAR will be performing a system upgrade at
          an unspecified time during the next few days. All STAR sites will be
          down for approximately 15 - 30 minutes during this time.
        </p>
      </div> */}
      <Hero
        bgImage="https://cdn.starsacramento.org/common/star-logo-ver-8.png"
        title="Upcoming Program Registrations"
        bgColor=""
        bgPosition="center top"
        style={{ backgroundColor: "white", opacity: ".9" }}
      >
        <Content
          title="STAR Summer Camp 2024 Registration Dates"
          columns="3"
          showTitle="true"
          // style={{ backgroundColor: "white", width: "82.5%"}}
        >
          <div>
            <p>Registration begins at 9:00 AM for all districts</p>
            <div>
              <h3>Western Placer USD</h3>
              <p>Monday, April 8th - <HashLink smooth to="/summer-info#wp">Click For More Info!</HashLink></p>
              <h3>Eureka/Loomis USD </h3>
              <p>Tuesday, April 9th - <HashLink smooth to="/summer-info#eu">Click For More Info!</HashLink></p>
              <h3>Rocklin Academy Family Of Schools </h3>
              <p>Wednesday, April 10th - <HashLink smooth to="/summer-info#rafos">Click For More Info!</HashLink></p>
            </div>
            <div>
              <p>
                <strong>
                  <em>Registration is now closed for the 23-24 school year</em>
                </strong>
              </p>
            </div>
          </div>
        </Content>
        <Content
          title="STAR Galaxy 2024-2025 Registration Dates"
          columns="3"
          showTitle="true"
        >
          <div>
            <p>Registration begins at 9:00 AM for all districts</p>
            <div>
              <h3>Western Placer USD</h3>
              <p>Monday, May 13th</p>
              <h3>Eureka/Loomis USD</h3>
              <p>Tuesday, May 14th</p>
              <h3>Rocklin Academy Family Of Schools</h3>
              <p>Wednesday, May 15th</p>
            </div>
          </div>
        </Content>
        {/* <CallToAction
          title="STAR Nova 23-24 Registration"
          info="For All Eureka USD and Bowman Charter School Families"
          date="Registration Is Now Open"
          btnText="Click Here To Enroll"
          url="https://reg.starsacramento.org"
          // hidden={"hidden"}
        ></CallToAction>
        <CallToAction
          title="STAR Galaxy 23-24 Registration"
          info="For All Families in EUSD, LUSD, WPUSD And Rocklin Academy Familiy Of Schools"
          date="Registration Is Now Open"
          btnText="Click Here To Enroll"
          url="https://reg.starsacramento.org"
          // hidden={"hidden"}
        ></CallToAction> */}
      </Hero>
      <Wrapper>
        <Content
          title="Programs"
          columns="3"
          borderColor="blue"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <h2>STAR Galaxy</h2>
            <p>
              STAR Galaxy is a before/after school program that offers a balance
              of academic support, enrichment classes, fitness and recreation
              programs as well as special events on campus, community events and
              activities.
            </p>
            <HashLink smooth to="/programs#galaxy">
              <Button>Go To STAR Galaxy</Button>
            </HashLink>
          </div>
          <div>
            <h2>STAR Nova</h2>
            <p>
              STAR Nova is an after school enrichment program that offers a
              specific selection of educational, recreational and enrichment
              programs, with classes that take place once or twice a week on
              school campuses.
            </p>
            <HashLink smooth to="/programs#nova">
              <Button>Go To STAR Nova</Button>
            </HashLink>
          </div>
          {/* <div>
            <h2>STAR Preschool</h2>
            <p>
              STAR Preschool provides caring and nurturing environments combined
              with curriculum that offers children a variety of rich experiences
              to enhance their knowledge and appreciation of the world around
              them.
            </p>
            <HashLink smooth to="/programs#preschool">
              <Button>Go To STAR Preschool</Button>
            </HashLink>
          </div> */}
          <div>
            <h2>STAR Camps</h2>
            <p>
              Summer Camp may be STAR's biggest attraction, but STAR also offers
              camps all year round! Whenever there is a break from school during
              Winter, Spring or Fall we have a camp for every season!
            </p>
            <HashLink smooth to="/programs#camps">
              <Button>Go To STAR Camps</Button>
            </HashLink>
          </div>
        </Content>
      </Wrapper>
      <Hero
        title="Our Mission"
        bgImage="https://cdn.starsacramento.org/common/pencils.jpg"
        // bgColor="orange"
        bgPosition="center center"
        // blendMode="darken"
      >
        <HeroGridP>
          STAR Education's mission is to offer extended educational,
          recreational, and enrichment programs to students; support and enhance
          school curriculum; improve student achievement; and expand the
          cultural base of young people through innovative, quality programs
          offered by highly qualified instructors.
        </HeroGridP>
        <HeroGridP>
          If you are interested in having STAR provide assemblies, special
          school events, GATE, professional development, full service
          education/enrichment programs or more at your school, please contact
          your school principal or call STAR directly at (916) 632-8407.
        </HeroGridP>
      </Hero>
      <Hero
        title="Testimonials"
        bgImage="https://cdn.starsacramento.org/common/summerKids.jpg"
        bgColor=""
        bgPosition="center center"
        blendMode="luminosity"
      >
        <Quote
          quote="My son absolutely LOVES the STAR program! If he had his way, he would go everyday to STAR!"
          cite="Kristie Perez - Lincoln Crossing STAR Parent"
        />
        <Quote
          quote="STAR is the best after school program I have seen in 27 years of teaching. Kids enjoy the special activities and can get exceptional guidance in homework. They are nurtured!"
          cite="Carla Krueger - 3rd Grade Teacher @ Placer Elementary"
        />
      </Hero>
    </div>
  );
};

export default HomePage;
