import Content from "../components/Content/Content";
import { Box, QuickGrid } from "../components/UtilityClasses/UtilityClasses";
import Wrapper from "../components/Wrapper/Wrapper";
import { HashLink } from "react-router-hash-link";
import Button from "../components/Button/Button";

const SummerInfoPage = () => {
  return (
    <>
      <Wrapper id="wp">
        <Content
          titleShow={false}
          columns="1"
          borderColor="green"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          contentWidth="90vw"
        >
          <h1>STAR Summer Camp: Western Placer USD</h1>
          <QuickGrid>
            <Box>
              <h4>Traditional Summer Camp Locations:</h4>
              <p>
                Lincoln Crossing Elementary in Lincoln | June 10th - August 9th
              </p>
              <h4>Pricing</h4>
              <p>$55 per day | 2 day per week minimum enrollment</p>
              <h4>Registration</h4>
              <p>Monday, April 8th 9:00 AM</p>
              <p>
                Open to all students in incoming K-7th grade, regardless of
                previous STAR enrollment
              </p>
              <HashLink smooth to="/summer-preview-wp">
                <Button>Western Placer Summer Calendar</Button>
              </HashLink>
            </Box>
          </QuickGrid>
        </Content>
      </Wrapper>
      <Wrapper id="eu">
        <Content
          titleShow={false}
          columns="1"
          borderColor="purple"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          contentWidth="90vw"
        >
          <h1>STAR Summer Camp: Eureka/Loomis USD</h1>
          <QuickGrid>
            <Box>
              <h4>Traditional Summer Camp Locations:</h4>
              <p>
                Maidu Elementary in Roseville | June 10th - July 26th <br />
                Greenhills Elementary in Granite Bay | July 29th - August 9th
              </p>
              <h4>Pricing</h4>
              <p>$55 per day | 2 day per week minimum enrollment</p>
              <h4>Registration</h4>
              <p>Tuesday, April 9th 9:00 AM</p>
              <p>
                Open to all students in incoming K-7th grade, regardless of
                previous STAR enrollment
              </p>
              <HashLink smooth to="/summer-preview-eu">
                <Button>Eureka/Loomis Summer Calendar</Button>
              </HashLink>
            </Box>
            <Box>
              <h4>ELO-P Funding</h4>
              <p>
                ELO-P funding is available for all summer camps June 10th - June
                14th and July 8th - August 9th
              </p>
            </Box>
            <Box>
              <h4>ELO-P Summer School Extended Day At Ridgeview</h4>
              <p>
                June 17th - July 3rd | 2:00 - 5:30 PM
                <br />
              </p>
              {/* <HashLink smooth to="/summer-preview-ed">
                <Button>View Extended Day Camps</Button>
              </HashLink> */}
            </Box>
            <Box>
              <h4>Spanish Camp at Maidu</h4>
              <p>June 10th - June 28th | 9:00 AM - 12:00 PM</p>
              <h4>Pricing</h4>
              <p>$200 per week | 5 day enrollment required</p>
              <p>ELO-P funding is not available for this camp</p>
              {/* <HashLink smooth to="/summer-preview-ed">
                <Button>View Spanish Camp</Button>
              </HashLink> */}
            </Box>
          </QuickGrid>
        </Content>
      </Wrapper>
      <Wrapper id="rafos">
        <Content
          titleShow={false}
          columns="1"
          borderColor="blue"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          contentWidth="90vw"
        >
          <h1>STAR Summer Camp: Rocklin Academy Family Of Schools</h1>
          <QuickGrid>
            <Box>
              <h4>Traditional Summer Camp Locations:</h4>
              <p>
                Rocklin Academy Gateway | June 10th - August 2nd
              </p>
              <h4>Pricing</h4>
              <p>$55 per day | 2 day per week minimum enrollment</p>
              <h4>Registration</h4>
              <p>Wednesday, April 10th 9:00 AM</p>
              <p>
                Open to all students in incoming K-7th grade, regardless of
                previous STAR enrollment
              </p>
              <HashLink smooth to="/summer-preview-ra">
                <Button>RAFOS Summer Calendar</Button>
              </HashLink>
            </Box>
            <Box>
              <h4>Summer School Extended Day</h4>
              <p>At Gateway and ARCA June 11th - July 3rd</p>
              <h4>Pricing</h4>
              <p>$30 per day | 2 day per week minimum enrollment</p>
              <h4>ELO-P</h4>
              <p>
                ELO-P funding is only available for Summer School Extended Day
              </p>
              {/* <HashLink smooth to="/summer-preview-ed">
                <Button>View Extended Day Camps</Button>
              </HashLink> */}
            </Box>
          </QuickGrid>
        </Content>
      </Wrapper>
    </>
  );
};

export default SummerInfoPage;
