// import { Link } from "react-router-dom";
import Button from "../components/Button/Button";
import Wrapper from "../components/Wrapper/Wrapper";
import Content from "../components/Content/Content";
// import Section from "../components/Section/Section";

const LocationsPage = () => {
  return (
    <>
      <Wrapper>
        <Content
          title="STAR Admin Main Office"
          columns="2"
          borderColor="blue"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <p>
              4145 Delmar Ave., Suite 1 <br />
              Rocklin, CA 95677 <br />
              ph. (916) 632-8407 <br />
              fax (916) 632-8427 <br />
              <a href="mailto:info@starsacramento.org">
                info@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/TYLZfjMZZdyNAw3g9"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>School District Websites</h3>
            <p>
              <a
                href="https://www.eurekausd.org/"
                target="_blank"
                rel="noreferrer"
              >
                Eureka Union School District
              </a>
              <br />
              <a
                href="https://www.loomis-usd.k12.ca.us/"
                target="_blank"
                rel="noreferrer"
              >
                Loomis Union School District
              </a>
              <br />
              <a href="https://www.wpusd.org/" target="_blank" rel="noreferrer">
                Western Placer Unified School District
              </a>
            </p>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="Eureka USD | Loomis USD"
          columns="3"
          borderColor="green"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <h3>Greenhills STAR | EUSD</h3>
            <p>
              8200 Greenhills Way <br />
              Granite Bay, CA 95746 <br />
              ph. (916) 791-8448 <br />
              Preschool ph. (916) 316-2339 <br />
              facility #: 313600445 <br />
              <a href="mailto:greenhills@starsacramento.org">
                greenhills@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/wrN3482AWWmFmhiYA"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Maidu STAR | EUSD</h3>
            <p>
              1950 Johnson Ranch Dr. <br />
              Roseville, CA 95661 <br />
              ph. (916) 782-8454 <br />
              {/* Preschool ph. (916) 303-0003 <br /> */}
              facility #: 313602542 <br />
              <a href="mailto:maidu@starsacramento.org">
                maidu@starsacramento.org
              </a>
              {/* <br />
              <a href="mailto:maiduprek@starsacramento.org">
                maiduprek@starsacramento.org
              </a> */}
            </p>
            <a
              href="https://goo.gl/maps/jHRoiNE7K4xvA8FN8"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Oakhills STAR &amp; Preschool | EUSD</h3>
            <p>
              9233 Twin School Rd. <br />
              Granite Bay, CA 95746 <br />
              ph. (916) 791-8442 <br />
              Preschool ph. (916) 303-0166 <br />
              facility #: 313600444 <br />
              <a href="mailto:oakhills@starsacramento.org">
                oakhills@starsacramento.org
              </a>
              <br />
              <a href="mailto:oakhillsprek@starsacramento.org">
                oakhillsprek@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/deXm4BmphXXWkCwf9"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Ridgeview STAR | EUSD</h3>
            <p>
              9177 Twin School Rd. <br />
              Granite Bay, CA 95746 <br />
              ph. (916) 316-4972 <br />
              facility #: 313625035 <br />
              <a href="mailto:ridgeview@starsacramento.org">
                ridegview@starsacramento.org
              </a>
            </p>
            <a
              href="http://maps.google.com/maps?q=9177%20Twin%20Schools%20Rd.%2C%20Granite%20Bay%2C%20CA%2095746"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Franklin STAR | LUSD</h3>
            <p>
              7050 Franklin School Rd. <br />
              Loomis, CA 95650 <br />
              ph. (916) 660-0610 <br />
              facility #: 313614600 <br />
              <a href="mailto:franklin@starsacramento.org">
                franklin@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/mLw8VMec95dwt7Mx9"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="Western Placer USD"
          columns="3"
          borderColor="orange"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <h3>Carlin C. Coppin STAR</h3>
            <p>
              150 E. 12th St. <br />
              Lincoln, CA 95648 <br />
              ph. (916) 434-8720 <br />
              facility #: 313621978 <br />
              <a href="mailto:coppin@starsacramento.org">
                coppin@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/oVenCEidD38mQheo9"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Creekside Oaks STAR</h3>
            <p>
              2030 1st St. <br />
              Lincoln, CA 95648 <br />
              ph. (916) 434-8085 <br />
              facility #: 313610174 <br />
              <a href="mailto:creekside@starsacramento.org">
                creekside@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/8p5JLHVTQVaB5AGr8"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Foskett Ranch STAR</h3>
            <p>
              1561 Joiner Pkwy. <br />
              Lincoln, CA 95648 <br />
              ph. (916) 434-5884 <br />
              {/* Preschool ph. (916) 303-0024 <br /> */}
              facility #: 313611146 <br />
              <a href="mailto:foskett@starsacramento.org">
                foskett@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/QSAEo1o2t8mgEY7R7"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Lincoln Crossing STAR</h3>
            <p>
              635 Groveland Ln. <br />
              Lincoln, CA 95648 <br />
              ph. (916) 409-0797 <br />
              facility #: 313611146 <br />
              <a href="mailto:lcrossing@starsacramento.org">
                lcrossing@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/N1KBBb3gDHbnxaN78"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Twelve Bridges STAR</h3>
            <p>
              2450 Eastridge Dr. <br />
              Lincoln, CA 95648 <br />
              ph. (916) 434-6542 <br />
              facility #: 313610173 <br />
              <a href="mailto:12bridges@starsacramento.org">
                12bridges@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/ooPr36T1pDx1crbF7"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Scott M. Leaman STAR</h3>
            <p>
              1200 Brentford Circle <br />
              Lincoln, CA 95648 <br />
              ph. (916) 250-9733 <br />
              facility #: 313623615 <br />
              <a href="mailto:leaman@starsacramento.org">
                leaman@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/VsWomiGDp3SFvnrP6"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="Rocklin Academy Family Of Schools"
          columns="3"
          borderColor="green"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <h3>Gateway STAR</h3>
            <p>
              6550 Lonetree Blvd. <br />
              Rocklin, CA 95765 <br />
              ph. (916) 303-0003 <br />
              facility #: 313624036 <br />
              <a href="mailto:gateway@starsacramento.org">
                gateway@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/2sdrLTPXyN3TdaycA"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>Rocklin Academy STAR</h3>
            <p>
              6532 Turnstone Way <br />
              Rocklin, CA 95765 <br />
              ph. (916) 303-0024 <br />
              facility #: 313624034 <br />
              <a href="mailto:rocklinacademy@starsacramento.org">
                rocklinacademy@starsacramento.org
              </a>
            </p>
            <a
              href="https://goo.gl/maps/hNasbzsfB9uGYoLg6"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
          <div>
            <h3>American River Collegiate Academy STAR</h3>
            <p>
              7755 Hazel Ave. <br />
              Orangevale, CA 95662 <br />
              ph. (916) 316-2339 <br />
              facility #: 313625040 <br />
              <a href="mailto:americanriver@starsacramento.org">
                americanriver@starsacramento.org
              </a>
              <br />
            </p>
            <a
              href="https://goo.gl/maps/Vs5msn2ysSppBJas6"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Map</Button>
            </a>
          </div>
        </Content>
      </Wrapper>
    </>
  );
};

export default LocationsPage;
