// Science, Technology Arts And Engineering

export const STEAMClasses = [
  {
    title: "The Science of Monsters",
    description:
      "Are you interested in discovering unusually strange and interesting creatures? If you have ever wondered about the legend and history of monsters, come join us in The Science of Monsters! In this unique science class, we will uncover more about monsters through hands on science challenges and experiments. We will be using materials such as vinegar, dry ice, and candy to explore the subjects of anatomy, mutation, and more! Sign up to delve into an exciting class of monster study!",
      highlight: "",
  },
  {
    title: "The Science of Star Wars",
    description: "Welcome, Young Padawan! Are you interested in learning about how spaceships launch and learning how lightsabers were created in Star Wars? If so, you are invited to attend the Science of Star Wars! In this class, you will discover the science behind one of the greatest movie franchises of all time! Along with the above mentioned activities, you will be participating in a Sarlaac Pit rescue challenge and discovering how the special effects in the movies were developed. You will even learn more about The Force through a static electricity experiment! Come join now and may The Force be with you!",
    highlight: "",
  },
  {
    title: "NEW - Engineer It!",
    description: "Bridges, towers, planes, trains and automobiles- what do all these things have in common? All of these things are designed and improved upon by engineers every day! In this class, we will work together to build a foundation of knowledge, much like engineers build, from the ground up. Together, we will learn how to take an idea and make it come to life through hands-on STEAM (Science, Technology, Engineering, Art, and Math) lessons using the Engineering Design Process in this constructive class. Let’s build together!",
    highlight: "",
  },
  {
    title: "NEW - Chemistry of Food",
    description: "How can an egg be bouncy? Why does cilantro taste like soap to some people? Literally everything around us is made up of chemicals. That includes all foods! The different kinds of chemicals they contain give the stuff we eat their flavors, nutrients, colors, and smells. Come join us in the Chemistry of Food where you’ll have the opportunity to investigate and experiment with all types of yummies. Who says you can’t play with your food?",
    highlight: "No peanut products will be used in this class; however, other allergens such as eggs may be used.",
  },
  {
    title: "NEW - All About Inventors",
    description: "Imagine a world without TV, phones, or video games- boring, right? All of these things and so many more wouldn’t be here without the brilliant people who invented them! In All About Inventors, you will learn the history behind some of the world’s most exciting innovations and get hands-on experience in creating your own! Are you ready to learn about items that are being used day-to-day, such as the television or an iPhone? Stay tuned to find out how all these awesome items were created and how they have evolved since!!",
    highlight: "",
  },
  {
    title: "Beyond STEAM- More STEAM by Popular Demand!",
    description: "Do you ever wonder what the future will be like? What type of technology will be invented? In Beyond STEAM, we are preparing for the future today. Through science, technology, engineering, art, and mathematics, we will be developing solutions to solve every day challenges. With hands-on projects, we will be building, creating and exploring to help us become innovators for the future. Don’t wait for someone else to invent future technology- help create it now!",
    highlight: "Use of school computers with STAR provided software; software installation required.",
  },
  {
    title: "NEW - STEAM Universe",
    description: "Are you someone who likes to take on a challenge? Do you like to think outside the box and explore new ideas? STEAM (Science, Technology, Engineering, Art, and Math) Universe needs your help! In this action-packed class, different scenarios will call on you to build, test and create in ways of your wildest STEAM dreams! If you’re up for a challenge and ready to face it fearlessly, STEAM Universe is the place for you!",
    highlight: "",
  },
  {
    title: "Grossology",
    description: "STAR Nova invites you to take a look into the beautiful, yet grotesque things that the Earth has to offer. From the awesomely awful to the surprisingly scuzzy, Grossology will take you on an adventure through hands-on scientific exploration. Come and discover the science behind some of your favorite delightfully disgusting and pleasantly putrid projects. CAUTION! - By attending Grossology you will experience a never-ending curiosity about the nasty, grody, and hideous discoveries of the Earth, and you may get a little messy in the process!",
    highlight: "",
  },
  {
    title: "Grosserology",
    description: "If you thought Grossology was nasty (but awesome), then get ready to experience EVEN GROSSER GROSSNESS in Grosserology! Discover the science behind some of your favorite undoubtedly repugnant projects. By attending Grosserology, be prepared to experience the world in a disgustingly different and nicely nasty way. These classes will be sure to test your gag reflex so gear up for more grossness and sign up today! Students do not have to have attended Grossology to sign up for Grosserology.",
    highlight: "",
  },
  {
    title: "Slime Science",
    description: "Do you love squishy, stretchy, fluffy slime? Do you learn best by exploring with your hands? Join Slime Science, where we explore the science behind slime! Together in Slime Science, we’ll construct bonds using fluffy slime, create satisfying textures, learn how chemical reactions work, create bubbling slime, experiment with edible slime, and much more! Sign up today to get your slime on!",
    highlight: "",
  },
  {
    title: "NEW - Nature Sciencey",
    description: "Nature can be defined as the natural, physical, or material world around us. When you combine ecology, botany, and life science, you get STAR’s Nature Science! If you love the outdoors and exploring the world around you, then come join us! You will be discovering more about the earth, plants, nutrients, and what natural phenomena happens to the earth every day through unique and exciting discussions, observations, and experiments. If you enjoy nature and science, sign up for Nature Science now!",
    highlight: "",
  },
  {
    title: "Kaboom Chemistry - NEW PROJECTS!",
    description: "Want to learn about chemistry through KABOOM EXPLOSIONS?! When you sign up for Kaboom Chemistry, you will be able to test science to the highest limits with hands-on experiments and exploration with kid friendly chemicals, shocking static electricity, and exothermic reactions. We cannot wait to have you become a STAR Scientist and join our lab as Kaboom Chemistry will BLOW your mind!",
    highlight: "",
  },
  {
    title: "NEW - LEGO Engineering",
    description: "Are you ready to LEGO to the next level? Do you dream of a world in block patterns? LEGO Engineering is a world where the possibilities are nearly endless. Learn new concepts of science and math while designing with LEGO bricks. You will have opportunities to build skyscrapers, racers, transport vehicles, and test your skills at how much weight you can design a bridge to hold. Sign up now to become the next LEGO Master.",
    highlight: "",
  },
  {
    title: "NEW - LEGO Engineering",
    description: "Are you ready to LEGO to the next level? Do you dream of a world in block patterns? LEGO Engineering is a world where the possibilities are nearly endless. Learn new concepts of science and math while designing with LEGO bricks. You will have opportunities to build skyscrapers, racers, transport vehicles, and test your skills at how much weight you can design a bridge to hold. Sign up now to become the next LEGO Master.",
    highlight: "",
  },
  {
    title: "NEW - Space STEAM",
    description: "Journey from Earth to the ends of the Universe! In Space STEAM, artists and engineers can come together to be inspired and learn about everything from rockets and spacecraft, to our Solar System and the Milky Way. Using STEAM (Science, Technology, Engineering, Art, and Math) concepts, you will get hands-on with space-themed experiments. Blast off into science and creativity with Space STEAM!",
    highlight: "",
  },
  {
    title: "NEW - LEGO Engineering",
    description: "Are you ready to LEGO to the next level? Do you dream of a world in block patterns? LEGO Engineering is a world where the possibilities are nearly endless. Learn new concepts of science and math while designing with LEGO bricks. You will have opportunities to build skyscrapers, racers, transport vehicles, and test your skills at how much weight you can design a bridge to hold. Sign up now to become the next LEGO Master.",
    highlight: "",
  },
  {
    title: "NEW - Slime And Everything Sublime",
    description: "Just when you thought you had enough slime, you get Slime And Everything Sublime! We welcome all slime experts and beginners alike! If you love all things squishy, stretchy, and fluffy, prepare to be amazed! Using the hands-on science of chemical reactions, you will make the CRAZIEST slimey inventions ever! Do you learn best by exploring through playing with cool textures and getting messy? If so, we welcome you to join us in Slime And Everything Sublime! Don’t miss out on your chance to climb aboard to set sail into the world of Slime And Everything Sublime!",
    highlight: "",
  },
  {
    title: "NEW - Life Hacks",
    description: "Do you need some inspiration for new activities or new ways to do the same ole things? Do you want to unleash your inner creativity? In Life Hacks, we put a new spin on daily life! We will find fun and innovative ways to design useful tools for you and your family. Life Hacks combines both science and art to challenge your brain power! Sign up today!",
    highlight: "",
  },
  {
    title: "The Science of Video Games",
    description: "Do you like to create and tell stories? Do you like using new technology? In this class, you will create your own stories through the technology of Stop Motion Animation. You will get to brainstorm story ideas, create a backdrop, and use a state-of-the-art app to bring your stories to life through the wonder of Stop Motion Animation. At the end of this class, you will showcase your movies in a small Film Festival held in your classroom. Don’t forget to sign up now!",
    highlight: "",
  },
  {
    title: "Superhero Science",
    description: "It’s a bird, it’s a plane, it’s Superhero Science! In this class, you will study the heroic characters from the world of DC and Marvel comics and discover how their powers apply to the everyday world of science. You will not only discover what made characters from Batman to Captain Marvel mighty and brave, but apply their powers to areas of science such as STEM, aeronautics, and entomology. You will apply your scientific knowledge through exciting hands on challenges and experiments. If you have the Superhero power, sign up now and in the spirit of the Avengers, assemble!",
    highlight: "",
  },
  {
    title: "Science of Sports",
    description: "Have you ever wondered why your coach tells you to follow through when you kick a soccer ball? Or why your hands sometimes hurt when you hit a baseball with your bat? Well, the answer is simple… SCIENCE! In Science of Sports, you will dig deep into all of these questions and more. Have fun conducting hands-on experiments with real sports equipment as you learn about the science behind some of your favorite extracurricular activities.",
    highlight: "",
  },
  {
    title: "New - Jurassic World",
    description: "Tails and claws and teeth, oh my! In this dinosaur-sized class, you will travel back in time over 200 million years to explore the world of the dinosaurs and learn all about the science of paleontology. Explore the world as it was from the Triassic through the Cretaceous time periods when the dinosaurs ruled the Earth and learn to separate fact from fiction through scientific discovery, fun hands-on projects and art activities. Join Jurassic World today!",
    highlight: "",
  },
  {
    title: "NEW - We’ve Got a Problem",
    description: "Students, we’ve got a problem... and it’s up to you to solve it! In each week of this hands-on class, you will put your problem-solving skills to the test as we explore different dilemmas. Develop practical solutions and improvements to inventions through a modified engineering process. In this project-based exploratory class, you will use STEAM (Science, Technology, Engineering, Art, and Math) concepts to solve some real-world problems. You will learn that there is no challenge too large for you. We’ve got a problem, but you’ve got a solution!",
    highlight: "",
  },
  {
    title: "NEW - Electrical Engineering",
    description: "What is energy? How does electricity work? The answers to these questions can be quite shocking! Join us in Electrical Engineering for an electrifying exploration of the invisible force that carries a very visible impact on our modern world. We are as positive as a proton that you will be illuminated in this highly energetic, exploratory class of electricity. Using hands-on science, you will make things ACTUALLY light up in Electrical Engineering!",
    highlight: "",
  },
  {
    title: "NEW - Let’s MAKE It!",
    description: "Do you enjoy making, tinkering, and creating? Do you ever spend your afternoon making different things from what you have at home? If you do, then this is the PERFECT class for you! In Let’s MAKE It, you will explore and create using the concepts of STEAM (Science, Technology, Engineering, Art, and Math). It is incredible what you can do when you combine both the creative and the technical! Let’s bring all of our creative brains together and see what we can MAKE.",
    highlight: "",
  },
  {
    title: "NEW - Mystery Marvels",
    description: "Do you ever wonder if certain things really work the way we have always been told they do? Have you ever heard a myth you just wanted to crack? In this class, we are going to explore different myths and mysteries using hands-on science in order to uncover the truth! If this sounds exciting to you, sign up for Mystery Marvels to discover some of the world’s most curious mysteries.",
    highlight: "",
  },
  {
    title: "NEW - Fun with Physics",
    description: "Are you asking yourself right now, “how can physics possibly be fun?” Then, join us in Fun with Physics to find out. We will explore concepts of Physics in a fun and exciting way! We are going to take a journey through hands-on activities to discover new and old ideas that will be enjoyable for all ages. If Fun with Physics sounds like something you’d LOVE to be a part of sign up today!",
    highlight: "",
  },
];

// Visual And Performing Arts

export const VAPAClasses = [
  {
    title: "Art Studio - NEW PROJECTS!",
    description:
      "Are you an art enthusiast who loves to create? Do you enjoy painting, assembling, and drawing? If so, then this is the perfect class for you! In Art Studio, you will design numerous art compositions while learning to manipulate tons of art mediums such as acrylic paint, colored pencils, watercolor and more. Learn various techniques while designing incredible original works of art! Sign up today to join the fun in Art Studio!",
      highlight: "",
  },
  {
    title: "Sketch Doodle Draw",
    description: "Do you love to draw, doodle or create life-like portraits? If so, then Sketch, Doodle, Draw is the class for you! You will explore various types of artistry such as symmetrical magazine illustrations, sprouting animals, zentangle, caricatures, 3-d shading, and thinking out of the box illustrations! You will be able to take home your very own sketch book at the end of class that displays all of your amazing art! This class has everything you will need to expand your knowledge as an amazing artist!",
    highlight: "",
  },
  {
    title: "Marker Madness",
    description: "Oh, the creative places you’ll go...when you sign up for Marker Madness! In this class, you will become the master of marker art and techniques and create works of art with Sharpies, brush pens, permanent markers, and washable markers. You will design on canvas, cardstock, and even faux stained glass. Sign up today to master the art of the marker!",
    highlight: "",
  },
  {
    title: "Street Art",
    description: "Trendy, cutting edge, awesome! Street Art is all of these things and more! Design a dripping cityscape, Keith Haring sculpture, stencil design, billboard design and street art that will awe your friends. Using spray paint, acrylic paints, cardboard, markers and more, you will learn stencil making, negative space design, installation and sculpture making. Sign up today to be ahead of the curve!",
    highlight: "",
  },
  {
    title: "NEW - Action Art",
    description: "Do you like art AND wish you could get out of your chair in class more? In Action Art, we are combining movement, teamwork, physics, relays, and art all together! During Action Art, you will learn elements of design, create amazing art, and get your heart pumping by getting up and moving. Join Action Art and experience an art class like you never have had before. Sign up now so we can run, jump, and move into the Action Art world!",
    highlight: "",
  },
  {
    title: "Abstract Art",
    description: "What is abstract art? Abstract art can be defined as art that does not exist in the real world, and achieves its effect using different lines, colors, shapes, forms, and textures. In this unique and fun art class, you will be introduced to and create various works of abstract art based on famous artists such as Romeo Britto, Piet Mondrian, and Pablo Picasso. Our lessons will be based on their famous works and concepts such as cubism and surrealism. You will use mixed media techniques and various art materials, such as paint, oil pastel, and watercolors to bring your art to life! In Abstract Art, you have the opportunity to use your imagination as inspiration for your art! If you love art and being original and creative, come join us today!",
    highlight: "Please note that most of our art will be messy, so please wear clothes that you don’t mind getting dirty.",
  },
  {
    title: "Bizarre Art",
    description: "Do you like to create out of the box masterpieces? Are you an out of the box thinker? Well, Bizarre Art is the class for you! In this class, you will discover surreal artists, techniques, and create your own unique pieces of art. You will use all sorts of different art mediums to create some really bizarre projects such as a fruit self portrait and a distorted image in the style of Salvador Dali. Sign up today to let your imagination soar through the surreal world of Bizarre Art!",
    highlight: "",
  },
  {
    title: "Nature Art",
    description: "Vincent van Gogh once said, “If you truly love nature, you will find beauty everywhere.” It’s time to embrace nature and utilize the elements to create incredible artwork! Come along on a journey to discover how pressed luminous fossils are made, harness the power of gravity to create a poured aurora borealis, investigate bugs, and use found objects to design usable paint brushes. Throughout this class, you will learn amazing facts about the natural world around us and use various artistic techniques to create masterpieces. Sign up today and let’s get back to the art in nature!",
    highlight: "",
  },
  {
    title: "Art Through The Ages",
    description: "Have you ever sat back and wondered where did all the the art in museums come from? Well, in this amazing class, we will travel through the decades of art styles and art masters, learning classical techniques and skills. We will start at the beginning with the prehistoric era and move into renaissance, cubism, abstract, and pop and modern art. Sign up and let’s paint, draw and design works of art, just like the masters!",
    highlight: "",
  },
  {
    title: "Sculpture Design",
    description: "What is the third dimension of art? Well in this specialty art class, you will find out and go beyond what your imagination is capable of thinking! You will discover various history about famous artists, styles, mediums, all while creating 3D art out of paper, cardboard, wood, paper mache, clay, and more. In this exciting world of art, your creations will pop up and out. Sign up for this class and become a part of the art world that will blow your mind into the third dimension of art!",
    highlight: "",
  },
  {
    title: "NEW - Fantastical Art",
    description: "Unicorns, Goblins, Gnomes, and Giants OH MY! Do you love fantasy, imaginary creatures, and things of mythical lore? If so, this is the class for you. You will explore the mythical world through multiple art mediums, including clay, paint and assemblage art. Sign up now and join the fantastical fun!",
    highlight: "",
  },
  {
    title: "3D Visual Reality Art",
    description: "Welcome to the world of 3D Visual Reality Art! You will get to experience an entire reality by manipulating your art work and watching it come alive with 3D glasses you construct yourself. Imagine your creativity becoming a reality right before your eyes! If you are ready to enter to the third dimension of art, put on your glasses and enjoy the ride.",
    highlight: "",
  },
  {
    title: "Global Art",
    description: "Have you ever wanted to travel around the globe and discover new and exciting places? In this class, you will discover new places through art and culture. We will explore destinations such as Japan, the Polynesian Island and the Middle East by creating geometric tiles, Koinobori Windsocks, metal tooling and more. Sign up today and let’s travel the globe together!",
    highlight: "",
  },
  {
    title: "Mixed Media Masterpiece",
    description: "What do you get when you mix paint, markers, collage, pastels and glue? A Mixed Media Masterpiece! Join this class and you will discover the joy of creating amazing art masterpiece with a mixture of art mediums. During this fun-filled, hands-on class, you will create a multitude of art projects such as skinny strip collages, 3D names, and wild portraits using the techniques of painting, sculpting, and stippling. Sign up for Mixed Media Masterpiece and let’s mix it up!",
    highlight: "",
  },
  {
    title: "New - Modern Art",
    description: "Join this amazing class and take a trip into the world of Modern Art! In Modern Art, you will enhance your current skills beyond using a traditional pen and paper. Along with new styles and techniques, you will be introduced to artists who helped define the modern era. If you love art and want to advance your knowledge, understanding, and skills, then join this class and explore the modern artist in you!",
    highlight: "",
  },
  {
    title: "Artscape",
    description: "Our planet is made up of beautiful landforms that are full of vibrant colors and texture! In Artscape, we will have the opportunity to ‘travel’ to different parts of the world to recreate inspiring sceneries. By the use of different art mediums, we will cultivate and develop art skills that could be used to create mountain ranges, deserts, forests and oceans. Let’s discover what worlds of art we can create in Artscape!",
    highlight: "",
  },
  {
    title: "New - A Blank Canvas",
    description: "Attention all aspiring artists and mini Monets- join us for an expressive journey through a plethora of painting techniques and styles. Express yourself with paint mediums of all sorts, including watercolors and finger paints! This class is perfect for all artistic abilities and interests. Bring your imagination to create original paintings in A Blank Canvas!",
    highlight: "",
  },
  {
    title: "Comic Book Adventure",
    description: "Pow! Bam! Bang! Come and join us as we dive into the pages of our Comic Book Adventure! You will get to create your very own comic using a variety of mediums, different pen stroke techniques, and hands-on modeling crafts to bring your comic masterpiece to life. You will learn about the history of how comic books are made and all about the legendary artists behind the scenes. Are you ready to unleash your creative mind in STAR’s Comic Book Adventures?",
    highlight: "",
  },
  {
    title: "STAR Theatre",
    description: "Come one, come all! Here is your chance to participate in a theatrical event! Sign up for STAR Theatre and you will learn the basics of acting and performance etiquette, stage blocking and character development. Every student will participate in a short play (we have speaking and non-speaking roles!), that you will perform for your family and friends on the final day of class.",
    highlight: "",
  },
  {
    title: "Improv",
    description: "Have you ever been in a situation where you couldn’t think of the right words to say? Maybe your teacher asked you a question you just could not answer?! It happens to all of us! Here in our improvisation class, you will learn how to “think on your feet,” so you will never be caught off guard again! Each day we will play a different improvisation game focusing on various concepts including, but not limited to, scene work and character development. On the final day of class, we will have a short performance showcasing the skills we have learned.",
    highlight: "",
  },
  {
    title: "New - Movie Magic",
    description: "Lights, camera, action! Have you ever wanted a behind the scenes look into how movies are made? If so, join us as we explore the secrets of Hollywood and the magic behind the movies in this blockbuster class. Learn all about the process of film making from booming sound effects to fancy lighting techniques and much more. No need to travel anywhere; we will bring Hollywood to you!",
    highlight: "",
  },
  {
    title: "Movie Special Effects",
    description: "Have you ever wondered how animation in movies work? Or how a car window can stay frosted for so long when the movie is filmed in the middle of summer? Or maybe you’ve wondered how they make a person look like an animal in live action movies and plays? In Movie Special Effects, come with us behind the scenes, where we explore, create, and learn all about these screen tricks and much more!",
    highlight: "",
  },
  {
    title: "NEW - DIY",
    description: "DIY- DO IT YOURSELF! Do you want to know how to create something you have seen, but not sure how? Join DIY and we will show you the way. Use your crafty skills to create fun and useful projects in this exciting, hands-on class. Experiment with art in a whole new dimension. You will amaze your families with what you can do YOURSELF when you sign up for DIY!",
    highlight: "",
  },
  {
    title: "NEW - The Art of Cartooning",
    description: "Calling all aspiring cartoonists! Do you like to draw and sketch? Have you ever looked at a cartoon and wondered how you could draw it? If so, join us to discover The Art of Cartooning through basic drawing techniques and your own creativity! You will gain knowledge behind famous cartoon characters and the history of professional artists and animators. If you want to sharpen your drawing skills, sign up for The Art of Cartooning today!",
    highlight: "",
  },
  {
    title: "NEW - Pop Culture Art",
    description: "Pop culture can be defined as popular shared attitudes and beliefs through movies, music, toys, games and more. When combined with art, pop culture is brought to life through eye-catching images and designs. In this unique art class, you will discover the history and enjoyment of pop culture and the striking and colorful world of pop art! You will apply your creativity and artistic skills to design artwork similar to classic and current pop artists like Andy Warhol and Burton Morris. If you love art and the world of pop culture, then come join us on an exciting journey into the world of Pop Culture Art!",
    highlight: "",
  },
  {
    title: "NEW - Oh Snap!",
    description: "Photography is so much more than just taking pictures. In Oh Snap!, experience a camera obscura, take pictures without using a camera, and hand-color a black-and-white photo. You will also learn to take pictures with, GASP!, a film camera! Learn the techniques professional photographers use to create their masterpieces, and go home with your very own. Forget your phone and enter a new world of photography in Oh Snap!",
    highlight: "",
  },
  {
    title: "NEW - Graphic Novel Design",
    description: "Do you like to read, write, and draw? Do you like to make up your own fantasy stories and want to write your own? Wait no more! Join us in Graphic Novel Design to create your very own illustrated novel using your own story and drawings. You will learn basic elements and techniques to strengthen your talent as an artist and storyteller. If you are ready to unleash your inner author/illustrator, sign up for Graphic Novel Design!",
    highlight: "",
  },
  {
    title: "NEW - Guitar",
    description: "Do you ever have dreams of learning how to play guitar but aren’t sure where to start? Maybe you’ve learned a few of the basics but want to take the next step? In this exciting six-week class, our experienced teachers will guide you through the world of guitar and teach you the basics of how to play sweetly in no time. All levels of experience and ability are welcome to join and we will even provide the guitars for you during class time.",
    highlight: "",
  },
  {
    title: "NEW - Ukelele",
    description: "Do you ever have dreams of learning how to play ukulele but aren’t sure where to start? Maybe you’ve learned a few of the basics but want to take the next step? In this exciting six-week class, our experienced teachers will guide you through the world of ukulele and teach you the basics of how to play sweetly in no time. All levels of experience and ability are welcome to join and we will even provide the ukuleles for you during class time.",
    highlight: "",
  },
  {
    title: "NEW - The Art of Fish",
    description: "Ever want to explore the depths of the ocean or the rivers? Want to learn about new fish and creatures that live below? The Art of Fish explores the shallow rivers to the deep ocean depths in an artistic way! Work with multiple mediums and explore different ways to create fish through techniques in printmaking, painting, and metallic markers. If you love fish, sign up for the Art of Fish today!",
    highlight: "",
  },
  {
    title: "RockSTAR - This class is for grades 1st - 8th",
    description: "RockSTAR is a division of STAR Education and is California’s largest non-profit music education program that brings music back into the schools while turning kids’ rock ‘n’ roll dreams into reality! RockSTAR employs professional musicians who help students develop their skills on guitar, bass, drums, and keyboard. With celebrity support from rock icons such as Slash, Santana, Miranda Cosgrove, Travis Barker, Britney Spears, Taylor Dayne, Brian McKnight, DMC, Chris Slade of AC/DC, Gene Simmons, Sammy Hagar and more, this is the most established, effective and entertaining program for any young person who is ready to rock!",
    highlight: "RockSTAR classes are 15 weeks long with bands of seven students or less who work together to perform one-two songs at local venue with all of the Sacramento-area RockSTAR bands. No previous experience required! RockSTAR provides all the instruments for this class.",
  },
];

// Classes For Older Students

export const OlderClasses = [
  {
    title: "Problem Solving Through STEAM",
    description:
      "Do you want to help design the new tomorrow? Do you want to gain skills so that you are ready to invent the world you want to live in? In Problem Solving Through STEAM, you will research, plan, design and engineer solutions for the world around us. This class utilizes technology to construct structures in a 3-dimensional model and solve problems such as how to keep water out of homes in flood zones. This is your chance to learn about science, technology, engineering, art and mathematics in a whole new way. Sign up today to become the innovator you have always wanted to be!",
      highlight: "",
  },
  {
    title: "Graphic Design",
    description:
      "Movie posters, magazine ads, billboards - who designs all these amazing images? In this class, you do! Join and discover ways to design digitally and physically, while creating original layouts and images. We will start with the origins of design and travel to contemporary times and discover many various techniques on the computer as well as by hand along the way.",
      highlight: "Use of school computers with STAR provided software; software installation required.",
  },
  {
    title: "Digital Illustration",
    description:
      "Unleash your inner skills! Come and join us to create fun digital illustrations in the Krita program. Krita is an art software program where you can draw cartoons, paint, animate, and edit photos. You will learn the basics of Krita and develop your skills to master extraordinary digital art pieces. Digital Illustration is a creative place to experience professional digital art and to sharpen your talent. At the end of the day, you will gain new knowledge and make magic where technology and art meet!",
      highlight: "",
  },
  {
    title: "Printmaking - NEW PROJECTS",
    description:
      "Are you fascinated by art stamps? Do you like getting your hands a little dirty and trying new things? Well, Printmaking is the class for you! Explore different art techniques used to recreate prints over and over! Create art by using pieces of nature, blowing bubbles, and pressing paint onto different kinds of surfaces. Some techniques are modern, while others date back to the 20th century! It is time to learn the ways of printmaking so you can express yourself in a uniquely artistic way.",
      highlight: "Students should be dressed appropriately, as some paints and materials may stain clothing.",
  },
  {
    title: "NEW - To Playwrite Or Not To Playwrite?",
    description:
      "To Playwrite Or Not To Playwrite, that is the question...and we have got the answer! Join us as we dive into the world of playwriting from script to stage and learn about what life is like behind the curtain. Together, we will explore all the aspects of writing a play, from script to soliloquy and marquee to monologue. If you believe that all the world’s a stage, this is the class for you!",
      highlight: "",
  },
  {
    title: "NEW - Guppy Tank - An Entrepreneur’s Class",
    description:
      "Calling all young entrepreneurs- are you ready to take your lemonade stands to the next level? Need some fresh ideas for your lawn mowing startup? Maybe you have a million-dollar idea for the next big business or invention? If so, join us in this class, where we can help you take your business skills and plans to the next level. Learn the secrets of success as we grow from tykes to tycoons together in the Guppy Tank!",
      highlight: "",
  },
  {
    title: "NEW - Digital Matte Making",
    description:
      "Take Photoshopping to the next level in this new art class! Introducing Digital Matte Making, an all new digital painting process created by exploring the software of GIMP. Using this software, you will experience digital art such as painting techniques, photo collaging, and concept art. You will explore new methods of painting skills and shortcut options to turn your artwork into a masterpiece. Join us on an adventure to the world of Digital Matte Making!",
      highlight: "",
  },
  {
    title: "NEW - Digital 2D Animation",
    description:
      "Welcome to the journey of Digital 2D Animation, where you will get to explore how to create a short animated scene on the computer. You will learn the basics of animation such as the 12 principles, golden poses, color scheme, and the history of where it all began. You will get to utilize Opentoonz, a software where you will get to create amazing quality animation, learn new techniques, and explore numerous options that are used by professional animators. Are you ready to digitally draw your very own animation? Sign up today for Digital 2D Animation!",
      highlight: "",
  },
]