import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: ${({ theme }) => theme.novaColor};
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  font-family: ${({ theme }) => theme.fntSans};
  align-items: center;

  @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Copyright = styled.div`
    font-size: 1rem;
    font-family: inherit;
    text-align: center;
`

export const Social = styled.div`
  margin: 0 auto;
  padding: 1rem;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 2rem;
  display: grid;

  span {
    font-family: inherit;
    font-size: 1rem;
  }

  & > * {
    margin: 0 1rem;
  }
`;

export const Nav = styled.nav`
  margin: 0 auto;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 700;
  text-decoration: underline;
  line-height: 1.75;
`;

export const Logo = styled.div`
  img {
    max-width: 50%;
    margin: 0 auto;
    display: block;

    @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
      display: initial;
    }
  }
`;
