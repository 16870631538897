import { Link } from "react-router-dom";
import Button from "../components/Button/Button";
// import CallToAction from "../components/CallToAction/CTA";
import STARCalendar from "../components/Calendar/Calendar";
import Hero from "../components/Hero/Hero";
import Header from "../components/Header/Header";
import Section from "../components/Section/Section";
import Wrapper from "../components/Wrapper/Wrapper";
// import Quote from "../components/Quote/Quote";
import Content from "../components/Content/Content";
// import Layout from "../components/Layout/Layout";
import {
  STARAccordion,
  STARAccordionItem,
  STARAccordionItemHeading,
  STARAccordionItemButton,
  STARAccordionItemPanel,
} from "../components/Accordion/Accordion";
import { HeroGridP } from "../components/Hero/Hero.style";
import { Important, Strong } from "../components/UtilityClasses/UtilityClasses";
import { STEAMClasses, VAPAClasses, OlderClasses } from "../utils/NovaCatalog";
// import { HashLink } from "react-router-hash-link";

const ProgramsPage = () => {
  return (
    <>
      <Hero
        title="STAR Galaxy - A Before/After School Program"
        bgImage="https://cdn.starsacramento.org/galaxy/galaxyBG.jpg"
        bgColor="blue"
        bgPosition="center center"
        blendMode="lighten"
        id="galaxy"
      >
        <div>
          <HeroGridP>
            STAR Galaxy is an on-campus after school program that offers a
            balance of academic support, enrichment classes, fitness and
            recreation programs, science, and performing arts. It’s a recipe for
            a balanced childhood and a preparation for future success! STAR
            Galaxy offers a daily dose of fun and learning, August through June,
            from the end of the school day until 6:00 PM. Some campuses provide
            before school care.
          </HeroGridP>
        </div>
      </Hero>
      <Header>
        <Wrapper>
          <Content
            columns="4"
            bgColor="rgba(255, 255, 255, 0.5)"
            contentWidth="80vw"
          >
            <div>
              <p data-emphasis="true">
                Students rotate through Homework Club,
                sports/fitness/recreation, and enrichment classes that include
                Science, Theatre, Art and more! All staff possess a passion for
                teaching and youth development and must go through a thorough
                background check and are certified in First Aid and CPR.
              </p>
            </div>
            <div>
              <p data-emphasis="true">
                Our programs are designed around California State Standards and
                project-based learning and stress the importance of
                team-building, leadership skills, and creative expression. By
                stimulating both their creative and critical thinking skills,
                STAR helps students discover their passions and encourages
                social and emotional development. STAR prides itself in
                encompassing all different learning styles to ensure that the
                needs of all students are met and fostered to reach their
                highest potential.
              </p>
            </div>
            <div>
              <h1>Galaxy Pricing</h1>
              <p>
                <strong>Multiple Family Discounts:</strong> A family with 2
                children in the program will receive a 10% discount on the
                lesser of the two monthly totals. A family with 3 or more
                children in the program will receive a 10% discount on monthly
                total.
              </p>
              <div>
                <nav>
                  <a
                    href="https://cdn.starsacramento.org/pdf/GreenhillsPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Greenhills STAR
                  </a>
                  <a
                    href="https://cdn.starsacramento.org/pdf/MaiduPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Maidu STAR
                  </a>
                  <a
                    href="https://cdn.starsacramento.org/pdf/OakhillsPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Oakhills STAR
                  </a>
                  <a
                    href="https://cdn.starsacramento.org/pdf/LoomisPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Loomis USD
                  </a>
                  <a
                    href="https://cdn.starsacramento.org/pdf/WesternPlacerPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Western Placer USD
                  </a>
                  <a
                    href="https://cdn.starsacramento.org/pdf/RAFOSPricing.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Rocklin Academy Family Of Schools
                  </a>
                </nav>
              </div>
            </div>
            <Section title="Program Benefits" titleSize="1.5rem">
              <ul>
                <li>Improved math and literacy standardized test scores!</li>
                <li>Scholarships available on sliding scale basis.</li>
                <li>Sibling Discounts!</li>
                <li>Small staff to student ratio.</li>
                <li>H.Y.P.E. Program for 4th - 6th graders.</li>
              </ul>
              <Link to="/calendar">
                <Button>Galaxy Calendar</Button>
              </Link>
              <a
                href="https://reg.starsacramento.org"
                rel="noreferrer"
                target="_blank"
                style={{ textAlign: "center" }}
              >
                <Button disabled>Enroll In Galaxy</Button>
              </a>
              <div>
              <p>
                <strong>
                  <em>Registration is now closed for the 23-24 school year</em>
                </strong>
              </p>
            </div>
            </Section>
          </Content>
        </Wrapper>
      </Header>
      <Hero
        title="STAR Nova - After School Enrichment"
        bgImage="https://cdn.starsacramento.org/nova/novaBG.jpg"
        bgColor="orange"
        bgPosition="center center"
        blendMode="overlay"
        id="nova"
      >
        <div>
          <HeroGridP>
            STAR NOVA is an after school enrichment program that offers a
            specific selection of educational, recreational and enrichment
            programs, with classes that take place once or twice a week on
            school campuses.
          </HeroGridP>
        </div>
      </Hero>
      <Header>
        <Wrapper>
          <Content
            columns="1"
            bgColor="rgba(255, 255, 255, 0.5)"
            contentWidth="80vw"
          >
            <div>
              <p data-emphasis="true">
                The STAR NOVA program offers a wide selection of educational
                enrichment programs, with classes that take place on school
                campuses. These exciting, standards-based classes blend
                academics and fun in a disguised learning method of instruction.
              </p>
            </div>
            <div>
              <a
                href="https://cdn.starsacramento.org/pdf/WorldLanguagesFlierWeb.pdf"
                rel="noreferrer"
                target="_blank"
                // style={{ textAlign: "center" }}
              >
                <Button>View World Languages Flier</Button>
              </a>
              <a
                href="https://reg.starsacramento.org"
                rel="noreferrer"
                target="_blank"
                // style={{ textAlign: "center" }}
              >
                <Button>Enroll In A Class</Button>
              </a>
            </div>
            <div>
              <h1>STAR Nova For Educators: Online Nova Catalog</h1>
              <small>
                Are you an educator looking to add STAR Nova or STAR Gate
                programs? View potential classes by using the online version of
                the catalog. Rather have a PDF version you can print?{" "}
                <a
                  href="https://cdn.starsacramento.org/pdf/STARNovaCatalog2022-2023Web.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  CLICK HERE
                </a>
              </small>
            </div>
            <div>
              <STARAccordion allowZeroExpanded="true">
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      How STAR Nova Works
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <div>
                      <p>
                        The STAR Nova program offers a wide selection of
                        educational, recreational and enrichment programs, with
                        classesF that take place on school campuses. STAR Nova
                        works with school staff and parent organizations to
                        provide the most relevant and exciting classes for
                        students.F
                      </p>
                      <p>
                        STAR Nova classes are aligned to the Common Core and
                        State Standards and provide a balance of fun and
                        real-world, hands-on learning. Using an innovative
                        disguised learning method, STAR Nova teaches, enriches
                        and provides excitement!
                      </p>
                      <p>
                        There are two ways to provide STAR Nova classes on your
                        campus. Both options are also a great way to supplement
                        your school's GATE program.
                      </p>
                      <ol>
                        <li>
                          Turn-Key: STAR Nova will create fliers, enroll
                          students, communicate with parents, and keep
                          attendance. For this option, the school only provides
                          a space and aides in promotion of class fliers,
                          including placement on website and delivery of
                          electronic and paper communications with families.
                          Cost for this program is covered by enrolled families.
                        </li>
                        <li>
                          School-sponsored: STAR Nova will contract with the
                          school or parent organization to provide these
                          classes. Typically, they are less expensive than
                          turn-key, as there is no registration fee. Many
                          schools raise funds through their parent organizations
                          for school-sponsored programs. In addition to
                          providing a space,the school promotes the class,
                          communicates with parents, keeps attendance and
                          provides a classroom. STAR Nova can provide
                          promotional fliers for this option.
                        </li>
                      </ol>
                      <p>
                        STAR Nova classes are available Monday-Friday, before,
                        during or after school. In addition, STAR Nova can
                        provide weekend events. The schedule is dependent on
                        staff availability and we recommend booking classes as
                        early as possible.
                      </p>
                      <p>
                        The ideal STAR Nova session is six weeks long, but we
                        understand there are constraints in school calendars.
                        Classes can be scheduled anywhere from four to ten weeks
                        long.
                      </p>
                    </div>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      Science, Technology, Arts &amp; Engineering
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    {STEAMClasses.map((STEAMClass, index) => (
                      <div key={index}>
                        <h1>{STEAMClass.title}</h1>
                        <p>{STEAMClass.description}</p>
                        <p>
                          <strong>{STEAMClass.highlight}</strong>
                        </p>
                      </div>
                    ))}
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      Visual &amp; Performing Arts
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    {VAPAClasses.map((VAPAClass, index) => (
                      <div key={index}>
                        <h1>{VAPAClass.title}</h1>
                        <p>{VAPAClass.description}</p>
                        <p>
                          <strong>{VAPAClass.highlight}</strong>
                        </p>
                      </div>
                    ))}
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      Classes For Older Students
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    {OlderClasses.map((OlderClass, index) => (
                      <div key={index}>
                        <h1>{OlderClass.title}</h1>
                        <p>{OlderClass.description}</p>
                        <p>
                          <strong>{OlderClass.highlight}</strong>
                        </p>
                      </div>
                    ))}
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      Family Nights
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <div>
                      <p>
                        Many of the STAR Nova classes can also make a wonderful
                        Family Night for your school or your GATE program.
                        Family participation in a child's education is very
                        important, and STAR Nova can partner with you to provide
                        an exciting night of learning and family bonding for
                        your school.
                      </p>
                      <p>
                        These events are typically two hours in the evening and
                        can host up to 250 people. The school provides the space
                        and promotes the event.
                      </p>
                      <p>
                        The most popular classes for these events include
                        Engineer It with ZOME, CSI, STEAM, Improv, and In it to
                        Win It!
                      </p>
                    </div>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      In-Class Workshops &amp; GATE Events
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <div>
                      <p>
                        Are you looking to host a GATE or school event? Look no
                        further than STAR Nova.
                      </p>
                      <p>
                        STAR Nova also provides in-class workshops for any grade
                        level. Favorites have included Inventors Club,
                        Assemblage Art and Mythbusters. Call us today to set up
                        a program to supplement your school's curriculum!
                      </p>
                      <p>
                        Let STAR NOVA host your next weekend or during or after
                        school GATE event! We can turn just about any of our
                        class offerings into a high-quality weekend event for a
                        up to 180 students.
                      </p>
                      <h5 className="text-align-center">
                        Space STEAM • Lego Engineering • Mini Makers • Art
                        Studio
                        <br />
                        Will It Work • Improv • STEAM
                      </h5>
                    </div>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
              </STARAccordion>
            </div>
            <a
              href="https://reg.starsacramento.org"
              rel="noreferrer"
              target="_blank"
              // style={{ textAlign: "center" }}
            >
              <Button>Enroll In A Class</Button>
            </a>
          </Content>
        </Wrapper>
      </Header>
      {/* <Hero
        title="STAR Preschool - Early Childhood Education"
        bgImage="https://cdn.starsacramento.org/preschool/prekGirls.jpg"
        bgColor="blue"
        bgPosition="center center"
        blendMode="multiply"
        id="preschool"
      >
        <div>
          <HeroGridP>
            STAR Preschool offers high quality curriculum-based, early childhood
            educational programs for families <br /> in the Eureka Union School
            District. STAR Preschool provides caring and nurturing environments
            combined with curriculum that offers children a variety of rich
            experiences to enhance their knowledge and appreciation of the world
            around them.
          </HeroGridP>
        </div>
      </Hero> */}
      {/* <Header>
        <Wrapper>
          <Content
            columns="2"
            bgColor="rgba(255, 255, 255, 0.5)"
            contentWidth="80vw"
          >
            <div>
              <p data-emphasis="true">
                Multi-Age Program (Ages 3 - 5)
                <br />
                What a great way to prepare your child for kindergarten! Classes
                are 5 days per week, 3.5 hours per day. Our program focuses on
                kindergarten readiness skills both social and academic. We use
                theme related activities and incorporate stories, art, music,
                dance, science exploration and sports as well as many other
                creative and fun activities.
              </p>
            </div>
            <div>
              <p data-emphasis="true">
                All students must be fully potty trained in order to be eligible
                for all STAR Preschool programs. Children Must Be 3 Or 4 By
                September 1st, 2023.
              </p>
            </div>
            <div>
              <Section title="Highlights" titleSize="1.5rem">
                <ul>
                  <li>
                    STAR Education works with your local schools and their
                    kindergarten teachers to ensure that your child will have
                    the necessary skills to successfully enter kindergarten.
                  </li>
                  <li>
                    All of the curriculum created by STAR Education is to State
                    Content Standards or exceeds those standards.
                  </li>
                  <li>
                    Class sizes are kept small to ensure that your child
                    receives the praise and attention that they deserve.
                  </li>
                  <li>
                        Extended day opportunities are available to students to enhance
                        their social experiences in a secure setting.
                      </li>
                  <li>
                    STAR Preschool follows local school district calendars
                    making it easy to plan family activities.
                  </li>
                </ul>
              </Section>
              <Section title="Registration Is Now Closed!">
                <div style={{ marginTop: "1rem" }}>
                  <a
                    href="mailto:preschool@starsacramento.org?subject=Oakhills Preschool Waitlist&body=I would like to add my child to the Oakhills Preschool wait list."
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>Email Wait List</Button>
                  </a>
                </div>
              </Section>
            </div>
            <div>
              <Section title="2023-2024 School Year" titleSize="1.5rem">
                <STARAccordion allowZeroExpanded="true">
                  <STARAccordionItem>
                    <STARAccordionItemHeading>
                      <STARAccordionItemButton>
                        Locations &amp; Pricing
                      </STARAccordionItemButton>
                    </STARAccordionItemHeading>
                    <STARAccordionItemPanel>
                      <div>
                        <header>Eureka USD</header>
                        <hr />
                        <p>
                          <strong>Oakhills Elementary: $675 Per Month</strong>
                        </p>
                        <small>
                          9233 Twin Schools Rd, Granite Bay (Rm 504)
                        </small>
                        <hr />
                        <p>
                          <strong>Hours:</strong>
                        </p>
                        <small>Monday - Friday: 8:30 AM - 12:00 PM</small>
                      </div>
                    </STARAccordionItemPanel>
                  </STARAccordionItem>
                  <STARAccordionItem>
                    <STARAccordionItemHeading>
                      <STARAccordionItemButton>
                        Registration
                      </STARAccordionItemButton>
                    </STARAccordionItemHeading>
                    <STARAccordionItemPanel>
                      <div>
                        <header>2023-2024 Preschool Registration</header>
                        <hr />
                        <p>
                          Monday, March 13<sup>th</sup> @ 9:00 AM
                          Registration Is Now Closed!
                        </p>
                        <div style={{ marginTop: "1rem" }}>
                          <a
                            href="mailto:preschool@starsacramento.org?subject=Oakhills Preschool Waitlist&body=I would like to add my child to the Oakhills Preschool wait list."
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button>Email Wait List</Button>
                          </a>
                        </div>
                      </div>
                    </STARAccordionItemPanel>
                  </STARAccordionItem>
                  <STARAccordionItem>
                    <STARAccordionItemHeading>
                      <STARAccordionItemButton>
                        Mandatory Orientation
                      </STARAccordionItemButton>
                    </STARAccordionItemHeading>
                    <STARAccordionItemPanel>
                      <div>
                        <header>2023-2024 Preschool Orientation</header>
                        <hr />
                        <p>Date: TBD</p>
                        <p>Time: TBD</p>
                        <p>No Preschool This Day</p>
                      </div>
                    </STARAccordionItemPanel>
                  </STARAccordionItem>
                  <STARAccordionItem>
                    <STARAccordionItemHeading>
                      <STARAccordionItemButton>
                        First Day of School
                      </STARAccordionItemButton>
                    </STARAccordionItemHeading>
                    <STARAccordionItemPanel>
                      <div>
                        <header>First Day For 2023-2024</header>
                        <hr />
                        <p>
                          Monday, August 28 <sup>th</sup>
                        </p>
                      </div>
                    </STARAccordionItemPanel>
                  </STARAccordionItem>
                </STARAccordion>
              </Section>
            </div>
          </Content>
        </Wrapper>
      </Header> */}
      <Hero
        title="STAR Camps - Summer &amp; Non-School Day Camps"
        bgImage="https://cdn.starsacramento.org/common/blueTexture.jpg"
        bgColor="blue"
        bgPosition="center center"
        blendMode="overlay"
        id="camps"
      >
        <div style={{ display: "none" }}>
          <Section title="STAR Summer Camp 2023" titleSize="1.5rem">
            <Section>
              <p>
                <Strong>Registration Is Now Open For All Families</Strong>
                {/* <Strong>Due to technical issues, registration is on hold until further notice.</Strong> */}
              </p>
              <a
                href="https://reg.starsacramento.org"
                target="_blank"
                rel="noreferrer"
              >
                <Button>Register Now!</Button>
              </a>
              <p>
                <Important>
                  Sign-ups are ONLY available online. A one time
                  (NON-REFUNDABLE) registration fee of $90 is required.
                </Important>
              </p>
            </Section>
            <Section
              title="Important Information Regarding Weeks"
              titleSize={"1.25rem"}
            >
              <p>
                <Important>
                  Eureka/Loomis Summer Camp WEEK 1 will begin on June 5th
                </Important>
              </p>
              <p>
                <Important>
                  Western Placer Summer Camp WEEK 1 will begin on June 12th
                </Important>
              </p>
            </Section>
            {/* <Section title="Summer Camp Preview" titleSize="1.25rem">
            <div>
              <small>
                Available Monday, March 6<sup>th</sup> 2023
              </small>
            </div>
            <HashLink smooth to="/summer-preview-eu#speu">
              <Button>Eureka/Loomis USD</Button>
            </HashLink>
            <HashLink smooth to="/summer-preview-wp#spwp">
              <Button>Western Placer USD</Button>
            </HashLink>
          </Section> */}
            <Section title="All Camps Are Part Time" titleSize="1.25rem">
              <p>
                Part time camps require students to be enrolled for a minimum of
                2 days of the camp.
              </p>
            </Section>
            <Section>
              <STARAccordion allowZeroExpanded="true">
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>Pricing</STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <p>$55 per day</p>
                    <small>(2 day minimum enrollment required)</small>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>Hours</STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <p>
                      <strong>Eureka/Loomis:</strong> 6:30 AM - 6:00 PM
                    </p>
                    <p>
                      <strong>Western Placer:</strong> 7:00 AM - 6:00 PM
                    </p>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      STAR Will Be Closed
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <ul>
                      <li>
                        {/* Monday, June 20<sup>th</sup> */}
                        Juneteenth: June 19<sup>th</sup>
                      </li>
                      <li>
                        July 4<sup>th</sup>
                      </li>
                    </ul>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
                <STARAccordionItem>
                  <STARAccordionItemHeading>
                    <STARAccordionItemButton>
                      Location Information
                    </STARAccordionItemButton>
                  </STARAccordionItemHeading>
                  <STARAccordionItemPanel>
                    <p>
                      <strong>
                        Eureka/Loomis: June 5<sup>th</sup> - July 28
                        <sup>th</sup>
                        {/* Eureka/Loomis: Dates And Locations TBA */}
                      </strong>
                      <br />
                      <a
                        href="https://goo.gl/maps/XPPeMMo93w1ejGsG7"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Maidu Elementary (Map)
                      </a>{" "}
                      in Roseville
                      <br />
                      (916) 782-8454
                    </p>
                    <p>
                      <strong>
                        Eureka/Loomis: July 31<sup>st</sup> - August 11
                        <sup>th</sup>
                        {/* Eureka/Loomis: Dates TBA */}
                      </strong>
                      <br />
                      <a
                        href="https://goo.gl/maps/ytkssZQ938C4SZhh9"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Greenhills Elementary (Map)
                      </a>{" "}
                      in Granite Bay
                      <br />
                      (916) 791-8448
                    </p>
                    <p>
                      <strong>
                        Western Placer: June 12<sup>th</sup> - August 11
                        <sup>th</sup>
                        {/* Western Placer: Dates And Locations TBA */}
                      </strong>
                      <br />
                      <a
                        href="https://goo.gl/maps/ooPr36T1pDx1crbF7"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Twelve Bridges Elementary (Map)
                      </a>{" "}
                      in Lincoln
                      <br />
                      (916) 434-6542
                    </p>
                  </STARAccordionItemPanel>
                </STARAccordionItem>
              </STARAccordion>
            </Section>
          </Section>
        </div>
        <Section title="Non-School Day Camps" titleSize="1.5rem">
          <p>
            Enrollment for all Non-School Day Camps is now done through your
            account dashboard. Please log into your{" "}
            <a
              href="https://my.starsacramento.org"
              rel="noreferrer"
              target="_blank"
            >
              STAR Account
            </a>{" "}
            to check for camps that your students are currently eligible to be
            enrolled in.{" "}
            <Important>Payment is due at the time of signing up.</Important>{" "}
            <Important>Hover over calendar event for camp location.</Important>
          </p>
          <p>
            Students are required to bring a lunch everyday that they attend
            camp. Do not send food items that need refrigeration or require
            heating.
          </p>
          <p>
            Guardians are required to show I.D. when picking up their
            child(ren). Guardians are required to sign in/out their child(ren)
            with a full legal signature.
          </p>
          <p>
            <Important>All Camp Fees Are Non-Refundable</Important>
          </p>
          <STARCalendar></STARCalendar>
        </Section>
      </Hero>
    </>
  );
};

export default ProgramsPage;
