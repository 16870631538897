// import Button from "../components/Button/Button";
import Wrapper from "../components/Wrapper/Wrapper";
import Content from "../components/Content/Content";
import Button from "../components/Button/Button";

const AboutPage = () => {
  return (
    <>
      <Wrapper>
        <Content
          title="Get Involved"
          columns="2"
          borderColor="blue"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <h3>Donate Now!</h3>
            <p>Make a monetary donation by click on a button</p>
            <a
              href="https://buy.stripe.com/dR616ugfE0a73Xa4gi"
              target="_blank"
              rel="noreferrer"
            >
              <Button>$1</Button>
            </a>
            <a
              href="https://donate.stripe.com/28o02q6F40a7eBOaEH"
              target="_blank"
              rel="noreferrer"
            >
              <Button>$5</Button>
            </a>
            <p>
              Or by mailing a check to the address below: <br />
              STAR Sacramento <br />
              4145 Delmar Ave., Suite 1 <br />
              Rocklin, CA 95677
            </p>
          </div>
          <div>
            <h3>Employment</h3>
            <p>
              To see current employment opportunities please{" "}
              <a
                href="https://www.indeed.com/jobs?q=Star+Education&=&aceid=&gclid=CjwKCAjwxr2iBhBJEiwAdXECwyMrQBgrp4MBJtGB-nbKHaDzN_WrPOB1QYjWUbjXNVTt2YuDqeq_jhoCJpkQAvD_BwE&gclsrc=aw.ds&from=mobRdr&utm_source=%2Fm%2F&utm_medium=redir&utm_campaign=dt&vjk=6080ce17cab767ef"
                target="_blank"
                rel="noreferrer"
              >
                Click Here
              </a>
              !
            </p>
            <p>
              STAR is looking for after-school teachers who are fun, talented,
              energetic and who can teach : Science, Theater, Art, Recreation,
              cooking, dance, chess, and more. They must be committed to working
              with children in Sacramento metro area public schools that provide
              daily academic, recreational, and creative classes for students.
            </p>
            <p>
              ENTHUSIASM and FLEXIBILITY are a must for this fast-paced &amp;
              high-energy atmosphere!
            </p>
            <p>
              Please send your resume along with a cover letter to{" "}
              <a href="mailto:employment@starsacramento.org">
                employment@starsacramento.org
              </a>{" "}
              or call (916) 632-8407 for more information regarding employment
              opportunities.
            </p>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="How Can I Help?"
          columns="1"
          borderColor="purple"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <p>
              There are many ways to support STAR and our commitment to
              improving education and inspiring children. The expenses of our
              extended education and enrichment programs are funded primarily
              through philanthropic contributions from individuals, corporations
              and foundations. Our generous supporters realize the importance
              and impact our STAR programs have made in the lives of children
              and our communities.
            </p>
            <p>
              We invite you to join us in creating a brighter future. Every
              gift, large or small, symbolizes your commitment to the well-being
              of our children and our community. Your gift of any amount makes a
              difference and we appreciate your consideration.
            </p>
            <p>
              STAR, Inc. is a 501(c) 3 non-profit organization and monetary
              donations are tax deductible.
            </p>
            <h3>Corporate and Foundation Donations</h3>
            <p>
              Does your company offer Foundation Grants, Employee Matching Funds
              or Corporate Donations? Your donation could help to raise
              awareness in your company of our program and our children"s needs.
            </p>
            <h3>In-Kind Donations</h3>
            <p>
              Do you own a winery, a chocolate factory, a rental company? Can
              your company donate goods or services that could be used by our
              educational programs?
            </p>
            <p>
              View the STAR Donations{" "}
              <a href="/" target="_blank" rel="noreferrer">
                WISH LIST
              </a>{" "}
              to see the items that we need.
            </p>
            <p>
              For more information about how you can help or to make a donation,
              please contact STAR Education at (916) 632-8407. You can also
              contact us by email:{" "}
              <a href="mailto:info@starsacramento.org">
                info@starsacramento.org
              </a>
            </p>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="Board Of Directors"
          columns="4"
          borderColor="purple"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <small>President</small>
            <h3>Katya Bozzi</h3>
            <small>Executive Director, STAR Inc.</small>
          </div>
          <div>
            <small>Vice President</small>
            <h3>Erick Bozzi, Jr.</h3>
            <small>Co-Founder, STAR Inc.</small>
          </div>
          <div>
            <small>Board Member</small>
            <h3>Katiana Bozzi</h3>
            <small>Co-Founder, STAR Inc.</small>
          </div>
          <div>
            <small>Vice President</small>
            <h3>Stephen Nemeth</h3>
          </div>
          <div>
            <small>Secretary</small>
            <h3>Makiko Murdochowicz</h3>
            <small>Associate Executive Director of Enrichment, STAR Inc.</small>
          </div>
          <div>
            <small>Board Member</small>
            <h3>Arlene Klasky</h3>
          </div>
          <div>
            <small>Board Member</small>
            <h3>Kourosh Bakhtiar</h3>
          </div>
          <div>
            <small>Board Member</small>
            <h3>Amanda Levy</h3>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="Who We Are"
          columns="1"
          borderColor="purple"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <p>
              STAR Education is a charitable 501(c)(3) non-profit organization
              that has been selected the leader in innovative educational
              programming and community-based cultural enrichment projects for
              over 25 years. STAR services more than 500 schools in over 60
              school districts, reaching over a million students and their
              families every year. STAR works with students, families, schools
              and communities to provide exceptional educational and cultural
              enrichment opportunities.
            </p>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content
          title="History"
          columns="1"
          borderColor="purple"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          showTitle="true"
        >
          <div>
            <p>
              STAR Education began its work in the 1980's, when greatly
              diminished resources for California public schools and latchkey
              children emerged as two major challenges to child welfare. In
              1986, husband and wife team Katya and Erick Bozzi Sr. activated
              parents, professionals in partnership with the 10th District PTA
              in their West LA community to address the critical need for safe,
              high quality educational and enrichment opportunities for
              children.
            </p>
            <p>
              Together, they mobilized alternative and innovative resources to
              enhance youth education and development, bolster the struggling
              public school in their community, and provide a safe and
              supportive environment for children after school. STAR recognized
              the need to gather community partners, universities, and local
              leaders to rally around their public schools to bring new
              resources to help schools and school districts realize their
              educational goals. In 1993, STAR Education was incorporated as a
              501(c)(3) non-profit organization.
            </p>
            <p>
              In 1997, Erick, Sr. and Katya Bozzi, and their children, Erick II
              and Katiana, founded the STAR Eco Station as a subsidiary of STAR
              Education in partnership with US Fish and Wildlife. The STAR Eco
              Station is an environmental science museum and exotic wildlife
              rescue facility in Culver City, Calif., where visitors have an
              opportunity to learn how they can be involved in the preservation
              of the planet and have the chance to interact with rescued
              endangered and exotic wildlife.
            </p>
            <p>
              What began over 25 years ago with two innovative parents, a
              handful of outstanding professionals from the community, and one
              elementary school in West Los Angeles has been cultivated into a
              large-scale effort including over 1000 highly-skilled employees,
              as well as partnerships with government agencies, private
              organizations, parents and community groups. STAR"s programs
              support and enhance school-day curriculum and broaden the
              amenities that schools offer their communities, reinforcing the
              public school site as both the community focal point and as a
              center for state of the art learning.
            </p>
            <p>
              Today, STAR benefits over a million learners throughout
              California. Each day, STAR comes to school campuses in over 60
              school districts throughout California to
              connect students to the lessons and the resources they need to
              become lifelong learners. Through the years, STAR has enabled and
              inspired students to attend major universities, choose exciting
              career paths, and do meaningful work throughout the world.
            </p>
          </div>
        </Content>
      </Wrapper>
    </>
  );
};

export default AboutPage;
